<script setup lang="ts">
const portraitImage = ref();

onMounted(() => {
  if (window.innerWidth < 600) {
    portraitImage.value = `${useCdnUrl()}/assets-2024/ever/ever-poster-phone.jpg`;
  } else {
    portraitImage.value = `${useCdnUrl()}/assets-2024/ever/ever-poster.jpg`;
  }
});
</script>

<template>
  <div class="feature ever-feature reverse">
    <HomeFeatureContentContainer>
      <img :src="`${useCdnUrl()}/assets-2024/ever/ever-logo.svg`" alt="Ever" />
      <h2>Intimate Vows,<br />Iconic Views</h2>
      <p>
        Welcome to EVER, where the magic of New York City becomes a main character in your love
        story. Wedding ceremonies, vow renewals, proposals, and promise pledges at EVER offer an
        unparalleled experience as you declare your love in one of the world's most iconic settings.
      </p>

      <p class="coming-soon">Be the first to celebrate your love at the iconic One Times Square</p>

      <TheButton to="/ever" tag="NuxtLink" class="ever-callout">Reserve your date</TheButton>
      <p class="note">
        Have questions about how to celebrate with us? Connect with our concierge at
        <a href="mailto:Ever@OneTimesSquare.com">Ever@OneTimesSquare.com</a>
      </p>
    </HomeFeatureContentContainer>

    <div class="feature-image-effect">
      <SplitImage
        :srcs="[
          `${useCdnUrl()}/assets-2024/ever/ever-poster-alt.jpg`,
          `${useCdnUrl()}/assets-2024/ever/ever-poster.jpg`,
        ]"
        :class="'align-image-right'"
      />
    </div>

    <div class="feature-image-mobile" :style="{ backgroundImage: `url(${portraitImage})` }">
      <img :src="`${useCdnUrl()}/assets-2024/ever/ever-logo.svg`" alt="Ever" />
    </div>
  </div>
</template>

<style lang="scss">
@use "@/assets/scss/queries";
.ever-feature {
  @include queries.for-size(phone-only) {
    height: auto !important;
    min-height: 100vh !important;
  }
  .feature-content {
    background-color: var(--ever-primary-color);

    .content-container {
      @include queries.for-size(tablet-landscape-up) {
        gap: 3.5rem !important;
        padding: 2rem 8rem 0 8rem;
      }
    }

    .ever-callout {
      background-color: var(--ever-complimentary-color);
      border-width: 0;

      &:hover {
        color: black;
      }
    }
  }

  .feature-image-effect {
    .split-image {
      .split-slider {
        background-color: var(--ever-primary-color) !important;
      }
    }
  }

  .feature-image-mobile {
    @media (max-width: 600px) {
      background-position: right bottom;
      background-size: cover;
    }
    @media (max-width: 360px) {
      padding-bottom: 10px;
      img {
        max-width: 45%;
      }
    }
  }
}
</style>
